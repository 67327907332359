<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Add iPhone/iPad Device</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="close(false)">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
<!--      <template v-if="loading">-->
<!--        <v-card-text>-->
<!--          <loading message="Creating iPhone/iPad Device..." :dialog="false" :loading="loading"></loading>-->
<!--        </v-card-text>-->
<!--      </template>-->
      <template v-if="currentStep === steps.input">
        <v-card-text class="pt-2">

          <v-alert type="warning" v-if="$store.getters.isAccountLocked">
            Your account is locked, which prevents you from adding new iOS devices.  Please unlock your account then try again.
          </v-alert>
          <template v-else>
            <h3>On iPhone/iPad we will track the domain (website) visited and optionally filter.</h3>
            <div class="caption">Due to technical limitations it's not possible to build a good solution that performs device wide screenshot accountability for iPhone/iPad.</div>
            <v-text-field
                class="mt-2"
                color="primary"
                v-model="device.name"
                label="Device Name"
                hint="What should this device be named?"
                :persistent-hint="true">
            </v-text-field>

            <v-radio-group
                color="primary"
                v-model="device.filterId"
                label="Choose a filter"
                column>
              <template
                  v-for="filter in filters"
              >
                <div :key="filter.id">
                  <v-radio
                      class="mb-0"
                      :key="filter.id"
                      :label="filter.name"
                      :value="filter.id"
                  >
                  </v-radio>
                  <div class="caption pl-8 mb-2">
                    {{filter.description}}
                  </div>
                </div>

              </template>
            </v-radio-group>
          </template>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addDohDevice"
            color="primary"
            :disabled="!device.name || !device.filterId || loading || $store.getters.isAccountLocked"
             :loading="loading"
            >
            Next
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else-if="currentStep === steps.setup">
        <v-card-text class="pt-2">
          <d-n-s-cloak-install-instructions
            :filter-id-prop="this.device.filterId"
            :device-id-prop="this.device.deviceId"
            :device-name-prop="this.device.name"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close(true)" color="primary">
            Done
          </v-btn>
        </v-card-actions>
      </template>
  </v-card>
</template>

<script>

  import AccountApi from '../../../aws/account/AccountApi'
  import filters from "../../../services/Filters";
  import SdnsStamp from "../../../services/doh/SdnsStamp";
  import DNSCloakInstallInstructions from "../../DnsCloak/DNSCloakInstallInstructions";

  const steps = {
    input: 'input',
    setup: 'setup'
  }

  function getDataInit() {
    return {
      loading: false,
      filters: filters,
      currentStep: steps.input,
      steps: steps,
      device: {
        filterId: null,
        description: null,
        name: null,
        deviceId: null
      }
    }
  }

  export default {
    name: "AddDohDevice",
    components: {DNSCloakInstallInstructions},
    data() {
      return getDataInit()
    },
    methods: {
      close(reload) {
        const initObj = getDataInit()
        for(let [k, v] of Object.entries(initObj)) {
          this[k] = v //reset the data object
        }
        this.$emit('on-close', reload)
      },
      async addDohDevice() {
        this.loading = true
        try {
          let response = await AccountApi.addDohDevice({
            deviceName: this.device.name,
            filterId: this.device.filterId
          })
          if(!response.status === 200) {
            throw new Error('failed to create device')
          }

          let deviceId = response.data.deviceId
          this.device.deviceId = deviceId

          this.currentStep = steps.setup
        } catch(e) {
          console.log(e)
          this.$swal('failed to create device')
        } finally {
          this.loading = false
        }
      }
    },
    computed: {
      sdnsStamp() {
        return SdnsStamp.calculateConfig(this.device.filterId, this.device.deviceId, this.device.name)
      }
    }
  }
</script>

<style scoped>

</style>
